import ReactWhatsappButton from "react-whatsapp-button";
import { useLayoutContext } from "../../context/layoutContext";

const WhatsAppBtn = () => {
  const getWhatsAppNumber = (data) => {
    let number = "";
    data.map((item) => {
      if (item.contact_type === 5) {
        number = item.contact_input;
      }
    });
    return number;
  };

  const { data: contactData, loading: contactLoading } = useLayoutContext();
  if (contactLoading || !contactData) return null;

  return (
    <ReactWhatsappButton
      countryCode={getWhatsAppNumber(contactData[0])?.slice(0, 2)}
      phoneNumber={getWhatsAppNumber(contactData[0])?.slice(2)}
      animated
    />
  );
};

export default WhatsAppBtn;
