import { Box, Stack, styled } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import contactUsBg from "../../../assets/images/contact-us-bg.webp";
import SecondaryContainer from "../../Containers/SecondaryContainer";
import SocialMedia from "../SocialMedia/SocialMedia";
import ContactInfo from "./ContactInfo";
import ContactUsForm from "./ContactUsForm";
import { contactInfoType } from "../../../helpers/types";
import lightContactBg from "../../../assets/images/light-contact-bg.png";

type Props = {
  contactInfo: contactInfoType;
  type?: "page";
};

const StyledHeaderTitle = styled("h1")({
  fontSize: "50px",
  fontWeight: 700,
  color: "#95EC84",
  maxWidth: "50%",
  "@media (max-width: 1440px)": {
    fontSize: "40px",
  },
  "@media (max-width: 960px)": {
    fontSize: "36px",
    maxWidth: "100%",
  },
  "@media (max-width: 600px)": {
    fontSize: "32px",
  },
});
const StyledHeaderInfo = styled("p")({
  fontSize: "35px",
  fontWeight: 600,
  color: "#fff",
  maxWidth: "50%",
  "@media (max-width: 1650px)": {
    fontSize: "30px",
  },
  "@media (max-width: 1440px)": {
    fontSize: "24px",
  },
  "@media (max-width:960px)": {
    fontSize: "16px",
    maxWidth: "100%",
  },
});
const ContactUs = ({ contactInfo, type }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      data-aos="fade-in"
      data-aos-duration="1000"
      sx={{
        backgroundImage: `url(${type ? lightContactBg : contactUsBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: { mobile: "60px 0", md: "80px 0", xl: "100px 0" },
      }}
    >
      <SecondaryContainer>
        <Stack spacing={4}>
          {/* header start */}
          {/* header at tablet & mobile view only */}
          <Box
            sx={{
              display: { mobile: "flex", sm: "none" },
              textAlign: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <StyledHeaderTitle
              sx={{
                color: type ? "#428E33" : "#95EC84",
              }}
            >
              {t("layout.contactUs.title")}
            </StyledHeaderTitle>
            <StyledHeaderInfo
              sx={{
                color: type ? "#707070" : "#95EC84",
              }}
            >
              {t("layout.contactUs.desc")}
            </StyledHeaderInfo>
          </Box>

          {/* <AnimateScale> */}
          <Box
            data-aos="zoom-in"
            sx={{
              width: "fit-content",
              display: { mobile: "none", sm: "flex" },
              flexDirection: "column",
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                top: "5%",
                width: "4px",
                height: "90%",
                backgroundColor: type ? "#428E33" : "#95EC84",
                borderRadius: "4px",
                left: "-35px",
              },
            }}
          >
            <StyledHeaderTitle
              sx={{
                color: type ? "#428E33" : "#95EC84",
              }}
            >
              {t("layout.contactUs.title")}
            </StyledHeaderTitle>
            <StyledHeaderInfo
              sx={{
                color: type ? "#707070" : "#95EC84",
              }}
            >
              {" "}
              {t("layout.contactUs.desc")}
            </StyledHeaderInfo>
          </Box>
          {/* </AnimateScale> */}
          <Box
            sx={{
              display: "flex",
              alignItems: { mobile: "center", xs: "unset" },
              gap: {
                mobile: "5px",
                xs: "20px",
                md: "60px",
                lg: "80px",
                xl: "120px",
              },
            }}
          >
            <Box
              sx={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                gap: { mobile: "1.5rem", sm: "2.25rem" },
                justifyContent: { mobile: "space-between", sm: "flex-end" },
              }}
            >
              {/* header at desktop view */}

              {/* end of header */}
              {/* contact Info */}
              <ContactInfo contactInfo={contactInfo} type={type} />

              <Box
                sx={{
                  display: { mobile: "none", xs: "flex" },
                  visibility: type ? "hidden" : "visible",
                }}
              >
                <SocialMedia color="white" size="big" />
              </Box>
            </Box>

            <ContactUsForm type={type} />
          </Box>
        </Stack>
      </SecondaryContainer>
    </Box>
  );
};

export default React.memo(ContactUs);
