//

import { Box, CircularProgress, ClickAwayListener } from "@mui/material";
import axios from "axios";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import MainButton from "../../Buttons/MainButton";
import ContactFields from "./ContactFields";

type Props = {
  type?: "page";
};

const initialValues = {
  name: "",
  email: "",
  address: "",
  message: "",
};

const ContactUsForm = ({ type }: Props) => {
  const { t } = useTranslation();

  const handleSubmit = async (values: any, actions: any) => {
    try {
      await sendMessage(values);
      handleSuccess();
    } catch (error) {
      handleError();
    } finally {
      actions.setSubmitting(false);
      actions.resetForm();
    }
  };

  const sendMessage = async (values: any) => {
    const formData = new FormData();
    formData.append("email", values.email);
    formData.append("name", values.name);
    formData.append("mobile", "123456789");
    formData.append("subject", values.address);
    formData.append("message", values.message);
    await axios.post(`${process.env.REACT_APP_API_URL}/send-message`, formData);
  };

  const handleSuccess = () => {
    toast.success(`${t("layout.contactUs.form.messageSent")}`, {
      toastId: "contactUsForm",
    });
  };

  const handleError = () => {
    toast.error(`${t("layout.contactUs.form.messageError")}`, {
      toastId: "contactUsForm",
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(`${t("layout.contactUs.form.validation.name")}`)
      .min(3, `${t("layout.contactUs.form.validation.nameLength")}`),
    email: Yup.string()
      .email(`${t("layout.contactUs.form.validation.invalidEmail")}`)
      .required(`${t("layout.contactUs.form.validation.email")}`),
    address: Yup.string().required(
      `${t("layout.contactUs.form.validation.title")}`
    ),
    message: Yup.string()
      .required(`${t("layout.contactUs.form.validation.message")}`)
      .min(10, `${t("layout.contactUs.form.validation.messageLength")}`),
  });

  return (
    <Box
      sx={{
        flex: "1",
        alignSelf: "flex-end",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, resetForm, setErrors }) => (
          <Form>
            <ClickAwayListener
              onClickAway={() => {
                // remove errors when user clicks away
                setErrors({
                  name: "",
                  email: "",
                  address: "",
                  message: "",
                });
              }}
            >
              <Box
                component={"div"}
                id="contact-us-form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { mobile: ".875rem", sm: "1rem", xl: "3.5rem" },
                }}
              >
                <ContactFields type={type} />
                <SubmitButton isSubmitting={isSubmitting} t={t} />
              </Box>
            </ClickAwayListener>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const SubmitButton = ({
  isSubmitting,
  t,
}: {
  isSubmitting: boolean;
  t: any;
}) => (
  <Box
    sx={{
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    }}
  >
    <MainButton
      aria-label="submit contact us form"
      type="submit"
      disabled={isSubmitting}
      endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
      disableElevation
      variant="contained"
    >
      {t("layout.contactUs.form.submit")}
    </MainButton>
  </Box>
);

export default ContactUsForm;
