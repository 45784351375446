import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import * as Yup from "yup";
import greenTriangle from "../../assets/images/small-green-triangle.svg";
import SecondaryContainer from "../Containers/SecondaryContainer";
import SmallerContainer from "../Containers/SmallerContainer";
import CustomTextField from "../CustomInputs/CustomTextField";
import { SmallTextTitle } from "../TextInfo/SmallTextInfo";

type Props = {};

const ComplaintsForm = (props: Props) => {
  const { t } = useTranslation();

  //form logic
  const handleSubmit = async (values: any, actions: any) => {
    try {
      await sendForm(values);
      handleSuccess();
      actions.resetForm();
    } catch (error) {
      handleError(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const sendForm = async (values: any) => {
    const formData = new FormData();
    //loop through values and append to formData
    for (const key in values) {
      formData.append(key, values[key]);
    }
    await axios.post(
      `${process.env.REACT_APP_API_URL}/submit_complaint`,
      formData
    );
  };

  const handleSuccess = () => {
    toast.success(`${t("complaints.form.applicationSent")}`, {
      toastId: "contactUsForm",
    });
  };

  const handleError = (errors: any) => {
    const errorValues = Object.values(errors.response.data.errors);
    toast.error(String(errorValues as any[][][0][0]), {
      toastId: "complaintForm",
    });
  };

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required(`${t("complaints.form.fullName")}`),
    email: Yup.string()
      .email(`${t("complaints.form.invalidEmail")}`)
      .required(`${t("complaints.form.email")}`),
    mobile_number: Yup.number().required(`${t("complaints.form.mobile")}`),
    complaint_title: Yup.string().required(`${t("complaints.form.title")}`),
    complaint_date: Yup.string().required(`${t("complaints.form.date")}`),
    complaint_details: Yup.string().required(`${t("complaints.form.details")}`),
    checked: Yup.boolean().oneOf([true]),
  });

  return (
    <Box
      data-aos="fade-zoom-in"
      sx={{
        marginTop: { mobile: "1.5rem", sm: "5rem", md: "7rem", lg: "10rem" },
        zIndex: 1,
      }}
    >
      <Formik
        initialValues={{
          full_name: "",
          national_id: "",
          email: "",
          mobile_number: "",
          phone_number: "",
          fax_number: "",
          complaint_title: "",
          complaint_date: "",
          complaint_details: "",
          complaint_file: "",
          checked: false,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, errors, touched, values }) => (
          <Form>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#f2f2f238",
                  padding: {
                    mobile: "30px 0",
                    md: "60px 0",
                    lg: "66px 0 100px 0",
                  },
                }}
              >
                <SecondaryContainer>
                  <SmallTextTitle
                    title={t("complaints.clientInfo")}
                    titleColor="#428E33"
                    gap={{ mobile: "12px", md: "50px" }}
                    startIcon={
                      <Box
                        sx={{
                          marginTop: {
                            mobile: "5px",
                            md: "10px",
                          },
                        }}
                        component="img"
                        src={greenTriangle}
                        alt={"start-icon"}
                      />
                    }
                  />
                </SecondaryContainer>
                <SmallerContainer>
                  <Box
                    sx={{
                      mt: "36px",
                      display: "grid",
                      gridTemplateColumns: "repeat(6, 1fr)",
                      columnGap: { mobile: ".8rem", md: "1rem", lg: "1.5rem" },
                      rowGap: { mobile: "1.5rem", md: "2rem", lg: "2.5rem" },
                    }}
                  >
                    <Box
                      sx={{
                        gridColumn: { mobile: "span 3", xs: "span 2" },
                      }}
                    >
                      <CustomTextField
                        label={t("complaints.name")}
                        name="full_name"
                        color="warning"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: { mobile: "span 3", xs: "span 2" },
                      }}
                    >
                      <CustomTextField
                        label={t("complaints.email")}
                        name="email"
                        color="warning"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: { mobile: "span 3", xs: "span 2" },
                      }}
                    >
                      <CustomTextField
                        label={t("complaints.idNumber")}
                        name="national_id"
                        color="warning"
                      />
                    </Box>

                    <Box
                      sx={{
                        gridColumn: { mobile: "span 3", xs: "span 2" },
                      }}
                    >
                      <CustomTextField
                        label={t("complaints.phoneNum")}
                        name="mobile_number"
                        color="warning"
                        type="number"
                      />
                    </Box>
                    <Box
                      sx={{
                        gridColumn: { mobile: "span 3", xs: "span 2" },
                      }}
                    >
                      <CustomTextField
                        label={t("complaints.phone")}
                        name="phone_number"
                        color="warning"
                        type="number"
                      />
                    </Box>

                    <Box
                      sx={{
                        gridColumn: { mobile: "span 3", xs: "span 2" },
                      }}
                    >
                      <CustomTextField
                        label={t("complaints.faxNum")}
                        name="fax_number"
                        color="warning"
                        type="number"
                      />
                    </Box>
                    <Box sx={{ gridColumn: "span 6" }}>
                      {" "}
                      <CustomTextField
                        label={t("complaints.address")}
                        name="complaint_title"
                        color="warning"
                      />
                    </Box>
                    <Box sx={{ gridColumn: "span 6" }}>
                      <CustomTextField
                        label={t("complaints.complaintDateAndPlace")}
                        name="complaint_date"
                        color="warning"
                      />{" "}
                    </Box>
                    <Box sx={{ gridColumn: "span 6" }}>
                      <CustomTextField
                        label={t("complaints.complaintDetails")}
                        name="complaint_details"
                        color="warning"
                      />
                    </Box>
                  </Box>
                </SmallerContainer>
              </Box>
              <SecondaryContainer>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { mobile: "40px", md: "60px", lg: "80px" },
                    mt: { mobile: "40px", md: "60px", lg: "80px" },
                  }}
                >
                  <SmallTextTitle
                    type="small"
                    gap={{ mobile: "12px", md: "50px" }}
                    titleColor="#428E33"
                    title={t("complaints.supportingDocuments")}
                    startIcon={
                      <Box
                        component="img"
                        sx={{
                          marginTop: {
                            mobile: "5px",
                          },
                        }}
                        src={greenTriangle}
                        alt="greenTriangle"
                      />
                    }
                  />
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="warning"
                      sx={{
                        width: {
                          mobile: "150px",
                          xs: "180px",
                          sm: "200px",
                          md: "250px",
                          lg: "300px",
                          xl: "350px",
                        },
                        height: {
                          mobile: "50px",
                          md: "60px",
                          lg: "70px",
                        },
                        borderRadius: "10px",
                        fontSize: { mobile: "18px", md: "20px", lg: "22px" },
                        fontWeight: "bold",
                      }}
                    >
                      <input
                        name="complaint_file"
                        onChange={(e) => {
                          const file = e?.target?.files?.[0];
                          if (file) {
                            setFieldValue("complaint_file", file);
                          }
                        }}
                        type="file"
                        style={{
                          position: "absolute",
                          opacity: "0",
                          width: "100%",
                          height: "100%",
                          top: "0",
                          left: "0",
                        }}
                      />
                      {t("jobs.attachments")}
                    </Button>
                    {touched.complaint_file && errors.complaint_file && (
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "error.main",
                          fontSize: "12px",
                          fontWeight: "bold",
                          position: "absolute",
                          bottom: "-20px",
                        }}
                      >
                        {t("jobs.form.complaint_file")}
                      </Typography>
                    )}
                    {values.complaint_file && (
                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                          bottom: "-30px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#707070",
                          }}
                        >
                          complaint file
                        </Typography>
                        <TiDelete
                          size="20px"
                          color="red"
                          cursor={"pointer"}
                          onClick={() => {
                            setFieldValue("complaint_file", null);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  <Field name="checked">
                    {({ field }: any) => (
                      <FormControlLabel
                        name="checked"
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            marginLeft: "20px",
                            color:
                              errors.checked && touched.checked
                                ? "red"
                                : "black",
                          },
                        }}
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            sx={{
                              alignSelf: "flex-start",
                              padding: "0",

                              "& .MuiSvgIcon-root": {
                                "& path": {
                                  fill: "#428E33",
                                },
                              },
                            }}
                          />
                        }
                        label={t("jobs.endorsement")}
                      />
                    )}
                  </Field>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    endIcon={isSubmitting && <CircularProgress size={20} />}
                    sx={{
                      width: {
                        mobile: "150px",
                        xs: "180px",
                        sm: "200px",
                        md: "210px",
                      },
                      height: {
                        mobile: "50px",
                        md: "60px",
                        lg: "70px",
                      },
                      borderRadius: "10px",
                      fontSize: "22px",
                      fontWeight: "bold",
                      alignSelf: "flex-end",
                    }}
                  >
                    {t("jobs.send")}
                  </Button>
                </Box>
              </SecondaryContainer>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ComplaintsForm;
