//ar

export const TRANSLATIONS_AR = {
  noPhotos: "لا يوجد صور",
  sar: "ر.س",
  month: "شهر",
  year: "سنة",
  monthlyReports: "التقارير الشهرية",
  dailyReports: "التقارير اليومية",
  noReports: "لا يوجد تقارير",
  searchPage: {
    title: "نتائج البحث",
  },
  errorPage: {
    title: "حدث خطأ ما",
    home: "الصفحة الرئيسية",
  },
  layout: {
    footer: {
      copyrights:
        "تصميم و برمجه ديموفنف،جميع الحقوق محفوظة لبيت التمويل السعودي الكويتي 2023",
      desc: "بيت التمويل السعودي الكويتي قادر على ضمان وتنفيذ المعاملات ذات الحجم الكبير ومرخص من قبل هيئة السوق المالية للقيام بجميع الخدمات المذكورة",
      links: {
        firstLink: {
          title: "مــن نــحــن",
          subLink1: "عن بيتك السعودية",
          subLink2: "هيئة الفتوى والرقابة",
          subLink3: "مجلس الإدارة",
          subLink4: "البحوث",
        },
        secondLink: {
          title: "المنتجات و الخدمات",
          subLink1: "ادارة الأصول",
          subLink2: "صندوق الاستثمار العقاري",
          subLink3: "ادارة الأوراق المالية",
          subLink4: "المصرفية الاستثمارية",
        },
        thirdLink: {
          title: "المركز الإعلامـــي",
          subLink1: "الأخبار و المستجدات",
          subLink2: "ألبوم الصور",
          subLink3: "مقاطع الفيديو",
        },
      },
    },
    contactUs: {
      title: "تواصل معنا",
      desc: "بيت التمويل السعودي الكويتي هو بوابتك لتحقيق الفرق الحقيقي ونمـوًا حقيقيًــا.",
      email: "البريد الإلكتروني",
      phone: "الهاتف",
      fax: "الفاكس",
      address: "العنوان",
      form: {
        submit: "أرسل",
        name: "الاسم",
        email: "البريد الإلكتروني",
        title: "الموضوع",
        message: "الرسالة",
        messageSent: "تم إرسال الرسالة بنجاح!",
        messageError: "حدث خطأ ، يرجى المحاولة مرة أخرى!",
        validation: {
          name: "الرجاء إدخال اسمك",
          nameLength: "يجب أن يكون الاسم على الأقل 3 أحرف",
          email: "الرجاء إدخال بريدك الإلكتروني",
          invalidEmail: "الرجاء إدخال بريد إلكتروني صالح",
          title: "الرجاء إدخال عنوان الموضوع",
          message: "الرجاء إدخال رسالتك",
          messageLength: "يجب أن تكون الرسالة على الأقل 10 أحرف",
        },
      },
    },
    header: {
      searchButton: "بحث",
      mainLinks: {
        aboutUs: "مــن نــحــن",
        services: "المـنتجات و الخدمــات",
        mediaCenter: "المركز الإعلامـــي",
        contactUs: "استثمر معنــا",
        jobs: "وظائف",
      },
      subLinks: {
        institutionalView: "عن بيتك السعودية",
        visionAndMission: "الرؤية والرسالة",
        ourStrength: "هيئة الفتوى والرقابة",
        boardMessage: "كلمة مجلس الإدارة",
        leadership: "مجلس الإدارة",
        organizationalStructure: "الهيكل التنظيمي",
        reports: "البحوث",
        board: "مجلس الإدارة",
        executiveManagement: "الإدارة التنفيذية",
        boardCommittees: "لجان مجلس الإدارة",
        awards: "التكريم و الجوائز",
        assetManagement: "إدارة الأصول",
        investmentBanking: "المصرفيه الاستثماريه",
        consulting: "المشورة",
        ordering: "الترتيب",
        realEstateInvestmentFund: "صندوق الاستثمار العقاري",
        investmentFunds: "الصناديق الاستثمارية",
        preservation: "الحفظ",
        securitiesManagement: "إدارة الأوراق المالية",
        investmentPortfolioManagement: "إدارة محافظ الاستثماريه",
        commonQuestions: "الأسئلة الشائعة",
        complaints: "الشكاوى",
        news: "الأخبار و المستجدات",
        photos: "ألبوم الصور",
        videos: "مقاطع الفيديو",
        socialResponsibility: "المسؤولية الاجتماعية",
        saudiEquityFund: "صندوق مشاركة للأسهم السعودية",
        auctionFund: "صندوق مشاركة للمزايدات و الصكوك",
        yourRights: "اعرف حقوقك",
        contactUs: "اتصل بنا",
      },
    },
  },
  homePage: {
    awards: {
      title: "جوائــــــز",
      desc: "بيت التمويل السعودي الكويتي",
      secondTitle: "التكريم و الجوائز",
    },
    mediaCenter: {
      title: "المركز الإعلامي",
      more: "المزيد",
      news: "الأخبار",
      photos: "الصور",
    },
    services: {
      title: "الخدمات",
      desc: "نقدم العديد من الخدمات المالية التي تساهم بدورها في خدمة عملائنا وخدمة متطلباتهم المختلفة",
      more: "المزيد",
      slideOne: "المصرفية الاستثمارية",
      slideTwo: "الحفظ",
    },
    aboutUs: {
      title: "عن بيتــــك السعودية",
      desc: "(SKFH) يُعتبَر بيت التمويل السعودي الكويتي اليوم أحد الشركات المالية الأسرع نموّاً في المملكة العربية السعودية. وهي شركة مساهمة مقفلة برأس مال قدره 320 مليون ريال سعودي مدفوع بالكامل ومقيدة بالسجل التجاري رقم (1010312522) وتاريخ 28 / 03 / 1430هـ صادر من مدينة الرياض وتزاول الشركة أنشطة التعامل والتعهد بالتغطية وإدارة الاستثمارات وتشغيل الصناديق والترتيب وتقديم المشورة والحفظ في سوق المال السعودي وذلك بموجب ترخيص هيئة السوق المالية رقم (08124-37) بتاريخ 05 / 11 / 2008 م",
      more: "المزيد",
    },
    sliderButton: "اشترك الآن",
  },
  mediaCard: {
    title: "مقابلة ولي العهد لرئيس الصين يضع إيران في مأزق",
    desc: "طهران - تتصاعد المخاوف في إيران من أن علاقات الصين المتصاعدة بسرعة مع المملكة العربية السعودية تتعارض مع تعهد بكين الطويل الأمد بالحياد.",
  },
  InstitutionalView: {
    visionAndMission: "الرؤية والرسالة",
    ourValues: "قيمنـــــا",
  },
  reports: {
    download: "تحميل",
    reportName: "اسم التقرير",
    date: "التاريخ",
    downloadReport: "تحميل التقرير",
  },
  leadership: {
    title: "سعادة الأستاذ :",
  },
  realEstateInvestmentFund: {
    title: "الصناديق",
    generalInvestmentFunds: "الصنــاديــق العـــامة",
    closedInvestmentFunds: "الصناديق الخاصة المغلقة",
    indirectInvestmentFunds: "صناديق التمويل غير المباشره",
    reports: "البحوث",
    generalInvestmentFundsKeys: {
      unitPrice: "سعر الوحدة",
      exchangeRate: "نسبه التغير",
      evaluationDate: "تاريخ التقييم",
    },
  },
  jobs: {
    title: "المعلومات المطلوبه للتسجيل",
    name: "الاسم",
    education: "المؤهل",
    email: "عنوان البريد الالكتروني",
    phone: "رقم الجوال",
    Years: "سنوات الخبرة",
    major: "مجال التخصص ( فئة العمل )",
    availableJobs: "الوظائف المتاحة",
    permanence: "نوع الدوام",
    address: "العنوان",
    nationality: "الجنسية",
    coverLetter: "الرسالة / خطاب المقدمة",
    desc: "تذكر أنّ سيرتك الذاتية تعطينا الانطباع الأوّل عنك؛ إنّها فرصتك الأولى لتخبرنا عن نفسك وعمّا يمكنك القيام به. لذا يُرجى التأكّد من تسليط الضوء على المهارات والخبرات التي اكتسبتها في حياتك المهنية حتى الآن، مع التركيز على إنجازاتك الرئيسية والتعريف عن نفسك على الصعيدَين المهني والشخصي و إذا كان لديك أي أسئلة أو استفسارات أو تعليقات أو اقتراحات، يُرجى التواصل معنا على البريد الإلكتروني",
    attachments: "ارفاق الملف",
    endorsement:
      "أقر بأن المعلومات المقدمة هنا كاملة وصحيحة ودقيقة. أتحمل المسؤولية الكاملة عن عدم صحة هذه المعلومات",
    send: "ارسال",
    form: {
      name: "من فضلك ادخل اسمك",
      email: "من فضلك ادخل بريدك الالكتروني",
      mobile: "من فضلك ادخل رقم الجوال",
      exp: "من فضلك ادخل سنوات الخبرة",
      certificate: "من فضلك ادخل المؤهل",
      nationality: "من فضلك ادخل الجنسية",
      invalidMail: "الرجاء إدخال بريد إلكتروني صالح",
      type: "من فضلك اختر نوع الدوام",
      department: "من فضلك اختر القسم",
      job: "من فضلك اختر الوظيفة",
      cv: "من فضلك ارفق السيرة الذاتية",
      applicationSent: "تم إرسال الطلب بنجاح!",
      applicationError: "حدث خطأ ، يرجى المحاولة مرة أخرى!",
    },
    jobType: {
      fullTime: "دوام كامل",
      partTime: "دوام جزئي",
      remote: "دوام عن بعد",
    },
  },
  complaints: {
    clientInfo: "معلومات العميل",
    name: "الاسم",
    idNumber: "رقم الهوية",
    email: "البريد الالكتروني",
    phone: "رقم الجوال",
    phoneNum: "رقم الهاتف",
    faxNum: "رقم الفاكس",
    address: "العنوان",
    complaintDateAndPlace: "تاريخ ومكان الشكوى",
    complaintDetails: "تفاصيل الشكوى",
    supportingDocuments: "برجاء تقديم الوثائق الداعمة إن وجدت",
    form: {
      fullName: "من فضلك ادخل الاسم ",
      email: "من فضلك ادخل البريد الالكتروني",
      mobile: "من فضلك ادخل رقم الجوال",
      title: "من فضلك ادخل عنوان الشكوى",
      date: "من فضلك ادخل تاريخ و مكان الشكوى",
      details: "من فضلك ادخل تفاصيل الشكوى",
      applicationSent: "تم إرسال الشكوي بنجاح!",
      invalidEmail: "الرجاء إدخال بريد إلكتروني صالح",
    },
  },
  news: "الأخبار",
  events: "الفعاليات",
  mainFund: {
    firstTab: "الصندوق",
    secondTab: "أخبار الصندوق",
    thirdTab: "الأسئلة الشائعه",
  },
  calendar: {
    day: "يوم",
    week: "أسبوع",
    month: "شهر",
    today: "اليوم",
  },
  investmentFund: {
    date: "تاريخ التشغيل :",
    size: "حجم الصندوق :",
  },
};
