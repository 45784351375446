//en

export const TRANSLATIONS_EN = {
  noPhotos: "No Photos",
  sar: "SAR",
  month: "Month",
  year: "Year",
  monthlyReports: "Monthly Reports",
  dailyReports: "Daily Reports",
  noReports: "There are no reports",

  searchPage: {
    title: "Search Results",
  },
  errorPage: {
    title: "Something went wrong",
    home: "Home Page",
  },
  layout: {
    footer: {
      copyrights:
        "Designed and programmed by Dimofinf, all rights reserved Saudi-Kuwaiti Finance House 2023",
      desc: "lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
      links: {
        firstLink: {
          title: "Who We are",
          subLink1: "About Saudi Baitk",
          subLink2: "Our Strength",
          subLink3: "Board of Directors",
          subLink4: "Researches",
        },
        secondLink: {
          title: "Products & Services",
          subLink1: "Asset Management",
          subLink2: "Real Estate Investment Fund",
          subLink3: "Securities Management",
          subLink4: "Investment Banking",
        },
        thirdLink: {
          title: "Investor Awareness",
          subLink1: "News & Updates",
          subLink2: "Photos Album",
          subLink3: "Videos",
        },
      },
    },
    contactUs: {
      title: "Contact Us",
      desc: "Saudi Kuwaiti Finance House is your gateway to making a real difference and growth.",
      email: "Email",
      phone: "Phone",
      fax: "Fax",
      address: "Address",
      form: {
        submit: "Send",
        name: "Name",
        email: "Email",
        title: "Subject",
        message: "Message",
        messageSent: "Message Sent Successfully !",
        messageError: "There has been an error, please try again !",
        validation: {
          name: "Please enter your name",
          nameLength: "Name must be at least 3 characters",
          email: "Please enter your email",
          invalidEmail: "Please enter a valid email",
          title: "Please enter the subject",
          message: "Please enter your message",
          messageLength: "Message must be at least 10 characters",
        },
      },
    },
    header: {
      mainLinks: {
        aboutUs: "About Saudi House",
        services: "Services & Products",
        mediaCenter: "Investor Awareness",
        contactUs: "Invest with us",
        jobs: "Jobs",
      },
      subLinks: {
        institutionalView: "About Saudi Baitk",
        visionAndMission: "Vision & Mission",
        ourStrength: "Our Strength",
        boardMessage: "Board Message",
        leadership: "Board of Directors",
        organizationalStructure: "Organizational Structure",
        reports: "Researches",
        board: "Board of Directors",
        executiveManagement: "Executive Management",
        boardCommittees: "Board Committees",
        awards: "Awards & Achievements",
        assetManagement: "Asset Management",
        investmentBanking: "Investment Banking",
        consulting: "Consulting",
        ordering: "Ordering",
        realEstateInvestmentFund: "Real Estate Investment Fund",
        investmentFunds: "Investment Funds",
        preservation: "Preservation",
        securitiesManagement: "Securities Management",
        investmentPortfolioManagement: "Investment Portfolio Management",
        commonQuestions: "Common Questions",
        complaints: "Complaints",
        news: "News & Updates",
        photos: "Photos Album",
        videos: "Videos",
        socialResponsibility: "Social Responsibility",
        saudiEquityFund: "Saudi Equity Fund",
        auctionFund: "Auction & Skuk Fund",
        yourRights: "Your Rights",
        contactUs: "Contact Us",
      },
      searchButton: "Search",
    },
  },
  homePage: {
    awards: {
      title: "Awards",
      desc: "Saudi Kuwaiti Finance House",
      secondTitle: "Awards & Achievements",
    },
    mediaCenter: {
      title: "Investor Awareness",
      more: "More",
      news: "News",
      photos: "Photos",
    },
    services: {
      title: "Services",
      desc: "We provide many financial services that contribute in serving our customers and serving their various requirements",
      more: "More",
      slideOne: "Investment Banking",
      slideTwo: "Custody",
    },
    aboutUs: {
      title: "About Saudi House",
      desc: "Today, Saudi Kuwaiti Finance House (SKFH) is one of the fastest growing financial companies in the Kingdom of Saudi Arabia. It is a closed joint stock company with a capital of 320 million Saudi riyals paid in full and registered with Commercial Register No. (1010312522) dated 03/28/1430 AH issued from the city of Riyadh. The company engages in dealing activities, underwriting, investment management, fund operation, arranging, advising and custody in the Saudi capital market, according to Capital Market Authority License No. (08124-37) dated 05/11/2008",
      more: "More",
    },
    sliderButton: "Subscribe",
  },
  mediaCard: {
    title:
      "The Crown Prince's interview with the President of China puts Iran in a dilemma",
    desc: "Tehran - Escalating relations in Iran that made it with the Kingdom of Saudi Arabia",
  },
  InstitutionalView: {
    visionAndMission: " Vision & Mission",
    ourValues: "Our Values",
  },
  reports: {
    download: "Download",
    reportName: "Report Name",
    date: "Date",
    downloadReport: "Download Report",
  },
  leadership: {
    title: "His Excellency :",
  },
  realEstateInvestmentFund: {
    title: "Investment Funds",
    generalInvestmentFunds: "General Investment Funds",
    closedInvestmentFunds: "Closed Investment Funds",
    indirectInvestmentFunds: "Indirect Investment Funds",
    reports: "Researches",
    generalInvestmentFundsKeys: {
      unitPrice: "Unit Price",
      exchangeRate: "Exchange Rate",
      evaluationDate: "Evaluation Date",
    },
  },
  jobs: {
    title: "Information required for registration",
    name: "Name",
    education: "Education",
    email: "Email",
    phone: "Phone",
    Years: "Exp Years",
    major: "Major",
    availableJobs: "Available Jobs",
    permanence: "Permanence type",
    address: "Address",
    nationality: "Nationality",
    coverLetter: "Cover Letter",
    desc: "Remember that your CV gives us the first impression of you; This is your first chance to tell us about yourself and what you can do. So please be sure to highlight the skills and experiences you have gained in your career so far, highlighting your key achievements and introduce yourself both professionally and personally. If you have any questions, concerns, comments or suggestions, please contact us at email",
    attachments: "Attach CV",
    endorsement:
      "I certify that the information provided herein is complete, true and accurate. I take full responsibility for the incorrectness of this information",
    send: "Send",
    form: {
      name: "Please enter your name",
      email: "Please enter your email",
      invalidMail: "Please enter a valid email",
      mobile: "Please enter your mobile number",
      exp: "Please enter your years of experience",
      certificate: "Please enter your certificate",
      nationality: "Please enter your nationality",
      type: "Please choose your permanence",
      department: "Please choose your department",
      job: "Please choose your job",
      cv: "Please attach your CV",
      applicationSent: "Application Sent Successfully !",
      applicationError: "There has been an error, please try again !",
    },
    jobType: {
      fullTime: "Full Time",
      partTime: "Part Time",
      remote: "Remote",
    },
  },
  complaints: {
    clientInfo: "Client Information",
    name: "Name",
    idNumber: "ID Number",
    email: "Email",
    phone: "telephone number",
    phoneNum: "Phone Number",
    faxNum: "Fax Number",
    address: "Address",
    complaintDateAndPlace: "Complaint Date and Place",
    complaintDetails: "Complaint Details",
    supportingDocuments: "Please provide supporting documents, if any",
    form: {
      fullName: "Please enter your name",
      email: "Please enter your email",
      invalidEmail: "Please enter a valid email",
      mobile: "Please enter your mobile number",
      title: "Please enter the title of your complaint",
      date: "Please enter the date and the location ",
      details: "Please enter the details of your complaint",
      applicationSent: "Complaint Sent Successfully !",
    },
  },
  news: "News",
  events: "Events",
  mainFund: {
    firstTab: "Fund",
    secondTab: "Fund News",
    thirdTab: "Common Questions",
  },
  calendar: {
    day: "Day",
    week: "Week",
    month: "Month",
    today: "Today",
  },
  investmentFund: {
    date: "Start Date :",
    size: "Fund Size :",
  },
};
