import { Box, Typography, styled } from "@mui/material";
import grayTriangle from "../../assets/images/gray-triangle.svg";
import SecondaryContainer from "../Containers/SecondaryContainer";
import { SmallTextTitle } from "../TextInfo/SmallTextInfo";
import GeneralInvestmentModal from "./InvestmentModalComponents/GeneralInvestmentModal";
import { useTranslation } from "react-i18next";

type Props = {
  generalFundData?: any;
};

const StyledTitle = styled("span")({
  fontSize: "24px",
  fontWeight: "bold",
  color: "#707070",
  "@media (max-width: 1650px)": {
    fontSize: "20px",
  },
});

const StyledMainInfo = styled("span")({
  fontSize: "20px",
  fontWeight: "bold",
  color: "#4EA83C",
  width: "170px",
  height: "70px",
  textOverflow: "ellipsis",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#F2F2F2",
  borderRadius: "8px",
  "@media (max-width: 1650px)": {
    width: "130px",
    fontSize: "18px",
  },
  "@media (max-width: 1440px)": {
    width: "110px",
    fontSize: "16px",
    height: "60px",
  },
});
const grayBoxStyles = {
  display: "flex",
  flexDirection: { mobile: "column", md: "row" },
  gap: { mobile: "20px", md: "24px", lg: "30px", xl: "40px" },
  alignItems: { mobile: "center", xs: "flex-start", md: "flex-end" },
};

const GeneralInvestmentFundTab = ({ generalFundData }: Props) => {
  const { t } = useTranslation();
  return (
    <SecondaryContainer>
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: {
            mobile: "40px",
            sm: "45px",
            md: "50px",
            lg: "60px",
            xl: "70px",
          },
        }}
      >
        {/* title-section */}
        <Box
          id="title-section"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: {
              mobile: "30px",
              sm: "35px",
              md: "40px",
              lg: "50px",
              xl: "70px",
            },
            position: "relative",
            paddingBottom: {
              mobile: "40px",
              sm: "50px",
              md: "70px",
              lg: "90px",
              xl: "110px",
            },
            "&::after": {
              content: '""',
              position: "absolute",
              width: { mobile: "240px", sm: "280px", md: "300px", xl: "340px" },
              height: "1px",
              backgroundColor: "#4EA83C",
              bottom: "0px",
              left: "0px",
            },
          }}
        >
          <SmallTextTitle
            title={generalFundData?.title}
            titleColor="#4EA83C"
            startIcon={
              <Box
                sx={{
                  width: { mobile: "16px", xl: "20px" },
                  height: { mobile: "16px", xl: "20px" },
                  backgroundColor: "#73BB65",
                  borderRadius: "50%",
                }}
              />
            }
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: { mobile: "wrap", xs: "nowrap" },
              gap: { mobile: "16px", md: "32px", lg: "40px", xl: "50px" },
              justifyContent: {
                mobile: "center",
                xs: "space-between",
                md: "flex-start",
              },
            }}
          >
            <Box sx={grayBoxStyles}>
              {" "}
              <StyledTitle>
                {" "}
                {t(
                  "realEstateInvestmentFund.generalInvestmentFundsKeys.unitPrice"
                )}
                :
              </StyledTitle>
              <StyledMainInfo>
                {Number(
                  generalFundData?.evaluation_details?.unit_price
                ).toFixed(4)}
              </StyledMainInfo>
            </Box>
            <Box sx={grayBoxStyles}>
              {" "}
              <StyledTitle>
                %{" "}
                {t(
                  "realEstateInvestmentFund.generalInvestmentFundsKeys.exchangeRate"
                )}
                :
              </StyledTitle>
              <StyledMainInfo>
                {" "}
                {generalFundData?.evaluation_details?.exchange_rate}{" "}
              </StyledMainInfo>
            </Box>
            <Box sx={grayBoxStyles}>
              <StyledTitle>
                {" "}
                {t(
                  "realEstateInvestmentFund.generalInvestmentFundsKeys.evaluationDate"
                )}
                :
              </StyledTitle>
              <StyledMainInfo>
                {" "}
                {generalFundData?.evaluation_details?.evaluation_date}{" "}
              </StyledMainInfo>
            </Box>
          </Box>
        </Box>
        {/* Reports-section */}
        {generalFundData?.reports?.length > 0 && (
          <Box id="Reports-section">
            <GeneralInvestmentModal reports={generalFundData?.reports} />
          </Box>
        )}
        {/* info-section */}
        <Box
          id="info-section"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <SmallTextTitle
            title={generalFundData?.fund_details?.title}
            startIcon={
              <Box
                component="img"
                src={grayTriangle}
                alt="gray-triangle"
                sx={{
                  marginTop: {
                    mobile: "7px",
                  },
                }}
              />
            }
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            {generalFundData?.fund_details?.details_information?.map(
              (item: { title: string; description: string }, index: number) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      gap: { mobile: "18px", xs: "30px", xl: "40px" },
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        backgroundColor: "#73BB65",
                        borderRadius: "50%",
                        alignSelf: "flex-start",
                        mt: { mobile: "9px" },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: { mobile: "16px", xs: "18px", md: "20px" },
                        fontWeight: "bold",
                        color: "#4EA83C",
                        display: "flex",
                        whiteSpace: "nowrap",
                        "& span": {
                          color: "#707070",
                          fontWeight: "normal",
                          whiteSpace: "pre-wrap",
                        },
                      }}
                    >
                      {item.title} : <span>{item.description}</span>
                    </Typography>
                  </Box>
                );
              }
            )}
          </Box>
        </Box>
      </Box>
    </SecondaryContainer>
  );
};

export default GeneralInvestmentFundTab;
