import { Box, Stack, styled } from "@mui/material";
// import AchivmentImage from "../../assets/images/achievment.png";

type Props = {
  date: string;
  title: string;
  image?: string;
  index?: number;
};
export const StyledDate = styled("div")({
  color: "#428E33",
  fontSize: "1.25rem",
  fontWeight: "700",
  //960px
  "@media (max-width: 960px)": {
    fontSize: "1rem",
  },
});
export const StyledTitle = styled("h3")({
  color: "#707070",
  fontSize: "1.5rem",
  fontWeight: "700",
  //1650px
  "@media (max-width: 1650px)": {
    fontSize: "1.25rem",
  },
  //960px
  "@media (max-width: 960px)": {
    fontSize: ".875rem",
  },
});

const AchievmentCard = ({ date, title, image, index }: Props) => {
  return (
    <Box
      // data-aos="flip-right"
      sx={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        padding: { mobile: "12px 10px", xs: "24px 20px", md: "0rem" },
        gap: "2rem",
        border: { mobile: "1px solid #428E33", md: "none" },
        borderRadius: "10px",
        width: { mobile: "100%" },
        boxShadow: { mobile: "4px 3px 17px rgba(0, 0, 0, 0.06)", md: "none" },
        // maxWidth: { mobile: "365px", sm: "unset" },
        height: { mobile: "auto", sm: "unset" },
      }}
    >
      <Box
        sx={{
          width: { mobile: "75px", md: "90px", lg: "100px", xl: "115px" },
          height: { mobile: "75px", md: "90px", lg: "100px", xl: "115px" },
          objectFit: "cover",
        }}
        component="img"
        src={image}
        alt="achievment"
      />
      <Stack spacing={1} justifyContent="center">
        <StyledDate>{date}</StyledDate>
        <StyledTitle>{title}</StyledTitle>
      </Stack>

      {/*     // divider {index
        ? index % 2 ==
          0(
            <Box
              sx={{
                width: "2px",
                backgroundColor: "#7070704a",
                height: "60%",
                position: "absolute",
                left: "88%",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            />
          )
        : null} */}
    </Box>
  );
};

export default AchievmentCard;
