import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ResponseProps {
  data: any;
}

const useGetMultiApi = (urls: string[]) => {
  const [data, setData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { i18n } = useTranslation();
  const language = i18n.language;
  const baseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses: AxiosResponse<ResponseProps>[] = await Promise.all(
          urls.map((url) => axios.get(`${baseUrl}${url}?lang=${language}`))
        );
        const data = responses.map((response) => response.data.data);
        setData(data);
      } catch (error) {
        toast.error("Error retrieving data from server");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [urls, language]);

  return { data, loading };
};

export default useGetMultiApi;
