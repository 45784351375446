import { Typography, Box } from "@mui/material";
import React from "react";
import greenDot from "../../assets/images/green-circle.svg";

type Props = {
  text: string;
};

const StyledListItem = ({ text }: Props) => {
  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: { mobile: "5px", xl: "30px" },
        "& img": {
          width: { mobile: "6px", md: "12px" },
          height: { mobile: "6px", md: "12px" },
          borderRadius: "50%",
          backgroundColor: "#428E33",
          marginRight: { mobile: "12px", md: "6px" },
        },
      }}
    >
      <img src={greenDot} alt="green dot" />
      <Typography
        sx={{
          fontSize: { mobile: "14px", xs: "1rem", sm: "1.25rem", lg: "1.5rem" },
          fontWeight: "600",
          color: "#428E33",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default StyledListItem;
