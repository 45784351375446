import { Box, Stack, styled } from "@mui/material";
import React from "react";
import { StyledTitle } from "../Services/StyledComponents";
import { useTranslation } from "react-i18next";
import TextInfo from "../TextInfo/TextInfo";
import SecondaryContainer from "../Containers/SecondaryContainer";
import AnimateX from "../Animations/AnimateX";
import StyledHeader from "../StyledComponents/StyledHeader";

type Props = {
  generalFundData?: any;
  title: string;
};

const BaitikHeader = ({ generalFundData, title }: Props) => {
  const StyledMainInfo = styled("span")({
    fontSize: "20px",
    fontWeight: "bold",
    color: "#4EA83C",
    width: "170px",
    height: "70px",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    "@media (max-width: 1650px)": {
      width: "130px",
      fontSize: "18px",
    },
    "@media (max-width: 1440px)": {
      width: "110px",
      fontSize: "16px",
      height: "60px",
    },
  });
  const grayBoxStyles = {
    display: "flex",
    flexDirection: { mobile: "column", md: "row" },
    gap: { mobile: "20px", md: "24px", lg: "30px", xl: "40px" },
    alignItems: { mobile: "center", xs: "flex-start", md: "flex-end" },
  };
  const { t } = useTranslation();
  return (
    // <SecondaryContainer>
    <Stack
      spacing={6}
      sx={{
        margin: "6rem 0 0 0",
      }}
    >
      <AnimateX direction="right">
        <StyledHeader
          sx={{
            marginLeft: { mobile: "16px", sm: "35px", md: "70px" },
          }}
        >
          {title}
        </StyledHeader>
      </AnimateX>
      <AnimateX direction="left">
        <Box
          sx={{
            position: "relative",
            marginLeft: { mobile: "16px", sm: "35px", md: "70px" },
            display: "flex",
            flexWrap: { mobile: "wrap", xs: "nowrap" },
            gap: { mobile: "16px", md: "32px", lg: "40px", xl: "50px" },
            justifyContent: {
              mobile: "center",
              xs: "space-between",
              md: "flex-start",
            },
            "&::before": {
              content: '""',
              backgroundColor: "#428E33",
              width: "4px",
              height: "100%",
              position: "absolute",
              left: "-70px",
            },
            // 1440px
            "@media (max-width: 1440px)": {
              fontSize: "1.25rem",
              lineHeight: "1.5",
              "&::before": {
                width: "3px",
                left: "-70px",
              },
            },
            "@media (max-width: 1280px)": {
              "&::before": {
                width: "3px",
                left: "-35px",
              },
            },
            //960px
            "@media (max-width: 960px)": {
              fontSize: "14px",
              lineHeight: "1.2",
              "&::before": {
                width: "2px",
                left: "-18px",
              },
            },
            //600px
            "@media (max-width: 600px)": {
              fontSize: "12px",
              lineHeight: "1.5",
              "&::before": {
                left: "-16px",
              },
            },
          }}
        >
          <Box sx={grayBoxStyles}>
            {" "}
            <StyledTitle
              sx={{
                color: "primary.main",
              }}
            >
              {" "}
              {t(
                "realEstateInvestmentFund.generalInvestmentFundsKeys.unitPrice"
              )}
              :
            </StyledTitle>
            <StyledMainInfo>
              {generalFundData?.unit_price}
              {" " + t("sar").toLocaleUpperCase()}
            </StyledMainInfo>
          </Box>
          <Box sx={grayBoxStyles}>
            {" "}
            <StyledTitle
              sx={{
                color: "primary.main",
              }}
            >
              %{" "}
              {t(
                "realEstateInvestmentFund.generalInvestmentFundsKeys.exchangeRate"
              )}
              :
            </StyledTitle>
            <StyledMainInfo> {generalFundData?.exchange_rate} </StyledMainInfo>
          </Box>
          <Box sx={grayBoxStyles}>
            <StyledTitle
              sx={{
                color: "primary.main",
              }}
            >
              {" "}
              {t(
                "realEstateInvestmentFund.generalInvestmentFundsKeys.evaluationDate"
              )}
              :
            </StyledTitle>
            <StyledMainInfo>
              {" "}
              {generalFundData?.evaluation_date}{" "}
            </StyledMainInfo>
          </Box>
        </Box>
      </AnimateX>
    </Stack>
    // </SecondaryContainer>
  );
};

export default BaitikHeader;
