import { Stack, styled } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  text: string;
};

const StyledTitle = styled("h6")({
  color: "#428E33",
  fontSize: "28px",
  fontWeight: "700",
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    width: "2px",
    height: "80%",
    borderRadius: "1px",
    top: "10%",
    left: "-35px",
    backgroundColor: "#428E33",
  },
  //less than 600px
  "@media (max-width: 600px)": {
    fontSize: "16px",

    "&::before": {
      left: "-16px",
    },
  },
});

const StyledText = styled("p")({
  color: "#505050",
  fontSize: "28px",
  fontWeight: "600",
  //less than 1650px
  "@media (max-width: 1650px)": {
    fontSize: "24px",
  },
  //less than 1440px
  "@media (max-width: 1440px)": {
    fontSize: "20px",
  },
  //less than 960px
  "@media (max-width: 960px)": {
    fontSize: "16px",
  },
  //less than 600px
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
});

const TextWithTitleCard = ({ title, text }: Props) => {
  return (
    <Stack
      spacing={{
        mobile: 1,
        md: 3,
        xl: 4,
      }}
    >
      <StyledTitle>{title}</StyledTitle>
      <StyledText>{text}</StyledText>
    </Stack>
  );
};

export default TextWithTitleCard;
