import { Box } from "@mui/material";
import SmallerContainer from "../Containers/SmallerContainer";
import StyledListItem from "../StyledComponents/StyledListItem";

type Props = {
  items: string[];
};

const TextList = ({ items }: Props) => {
  return (
    <>
      {items && items.length > 0 ? (
        <Box
          component="ul"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { mobile: "8px", lg: "16px" },
          }}
        >
          {items?.map((item, index) => (
            <StyledListItem key={index} text={item} />
          ))}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default TextList;
